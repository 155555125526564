



















































import Vue from 'vue';
import {
  Faq,
  FaqConnection,
  FaqEdge,
  FaqTypeInfo,
  SERVICE,
} from '@/domain/faqs/model/Faq';
import { Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { FAQ_CATEGORY_LIST, FAQS } from '@/domain/faqs/queries/query';
import FaqTabs from '@/domain/faqs/components/FaqTabs.vue';
import FaqTableForm from '@/domain/faqs/components/FaqTableForm.vue';

export default Vue.extend({
  name: 'HostFaqContainer',
  components: {
    FaqTableForm,
    FaqTabs,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data(): {
    faqs: Faq[];
    loading: boolean;
    tabIndex: number;
    filter: { forHostOnly: true; questionLike: '' };
    page: number;
    size: number;
    totalCount: number;
    categories: FaqTypeInfo[];
    tabValue: string | undefined;
  } {
    return {
      faqs: [],
      loading: true,
      tabIndex: 0,
      filter: {
        forHostOnly: true,
        questionLike: '',
      },
      page: 1,
      size: 20,
      totalCount: 0,
      categories: [],
      tabValue: undefined,
    };
  },
  watch: {
    $route(): void {
      this.$data.filter.forHostOnly =
        this.$route.params.faqType == SERVICE.toLowerCase() ? false : true;
      delete this.$data.filter.type;
      //tab 초기화
      (this as any).moveTab(undefined);

      this.$apollo.queries.faqs.refetch();
    },
  },
  created() {
    this.$data.filter.forHostOnly =
      this.$route.params.faqType == SERVICE.toLowerCase() ? false : true;
  },
  methods: {
    getFaqList(): void {
      this.$apollo.queries.faqs.refetch();
    },
    moveTab(type: string | undefined) {
      if (type) {
        this.$data.filter.type = type;
      } else {
        delete this.$data.filter.type;
      }
      this.$data.tabValue = type;
      this.$data.page = 1;
      this.$apollo.queries.faqs.refetch();
    },
    // detailFaq(): void {
    //   this.$router.push({
    //     name: 'HostFaqDetail',
    //     params: {
    //       forHostOnly: this.$data.filter.forHostOnly.toString(),
    //     },
    //   });
    // },
  },
  apollo: {
    faqs: {
      query: FAQS,
      variables() {
        return {
          filter: this.$data.filter,
          page: Number(this.$data.page),
          size: Number(this.$data.size),
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(result: ApolloQueryResult<{ board: { faqs: FaqConnection } }>) {
        this.$data.totalCount = result.data.board.faqs.totalCount;
        this.$data.faqs = result.data.board.faqs.edges.map((row: FaqEdge) => {
          return row.node;
        });

        this.$data.loading = false;
      },
      update: data => {
        return data.board.faqs;
      },
    },
    faqTypes: {
      query: FAQ_CATEGORY_LIST,
      variables() {
        return {
          param: { forHostOnly: this.$data.filter.forHostOnly },
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(result: ApolloQueryResult<{ board: { faqTypes: any[] } }>) {
        this.$data.categories = result.data.board.faqTypes;
      },
      update: data => {
        return data.board.faqTypes;
      },
    },
  },
});
